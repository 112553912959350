import { Box } from "@mui/material";
import { BACKEND_API } from "api";

const UserImage = ({ image, size = "60px"}) => {
    return (
        <Box width={size} height={size}>
            <img
            // profile image (circular)
                style={{ objectFit: "cover", borderRadius: "50%" }}
                width={size}
                height={size}
                alt="user"
                // src={`http://localhost:3001/assets/${image}`}
                src={`${BACKEND_API}/assets/${image}`}
            />
        </Box>
    );
}

export default UserImage;